<template>
  <div class="sld_chat_page">
    <chatLeftList
      @curChatMemInfo="curChatMemInfo"
      @switchMember="switchMember"
      ref="left"
      :connectBaseData="connectBaseData"
    />

    <div
      class="chat_zone"
      :style="{ background: curChatMemberId ? '#fff' : '#f1f1f2' }"
    >
      <section v-show="curChatMemberId">
        <div class="top">
          <p class="top_title">
            {{ curChatMemberInfo.data.memberName }}&nbsp;&nbsp;{{
              chatSourceUrl
            }}
          </p>
        </div>
        <div class="chat_wrap">
          <!-- 聊天界面框start -->
          <div
            class="chat_window"
            :style="'height:' + (clientHeight - 339) + 'px'"
          >
            <el-scrollbar ref="scroll" id="chat">
              <!-- <div v-if="msgList.list.length>0"> -->
              <div v-for="(item, index) in msgList.list" :key="index">
                <div
                  v-if="
                    index == 0 ||
                    (index > 0 &&
                      $isShowTime(
                        msgList.list[index - 1].addTime,
                        item.addTime
                      ))
                  "
                  class="time_line"
                >
                  {{ $formatChatTime(item.addTime) }}
                </div>

               

                <!-- 会员发送的信息start -->
                <template v-if="item.userType == 1">


                       <div class="chat_ladder user" v-if="item.msgType == 6">
                    <img :src="item.memberAvatar" class="conten_left" />

                    <div class="content_right">
                      <audio
                        controls
                        :src="JSON.parse(item.msgContent).url"
                      ></audio>
                    </div>
                  </div>



                  <!-- 文本类型start -->
                  <div class="chat_ladder user" v-if="item.msgType == 1">
                    <img :src="item.memberAvatar" class="conten_left" />
                    <div class="content_right">
                      <p>{{ item.memberName }}</p>
                      <span
                        class="content_text text_type"
                        v-html="JSON.parse(item.msgContent).content"
                      ></span>
                    </div>
                  </div>
                  <!-- 文本类型end -->

                  <!-- 图片类型start -->
                  <div class="chat_ladder user" v-if="item.msgType == 2">
                    <img :src="item.memberAvatar" class="conten_left" />
                    <div class="content_right">
                      <p class="name">{{ item.memberName }}</p>
                      <div class="content_text image_type">
                        <el-image
                          :src="JSON.parse(item.msgContent).pic"
                          :preview-src-list="[JSON.parse(item.msgContent).pic]"
                          hide-on-click-modal="true"
                        >
                          <template #placeholder>
                            <div class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </template>
                        </el-image>
                      </div>
                    </div>
                  </div>
                  <!-- 图片类型end -->

                  <!-- 商品类型start -->
                  <div class="chat_ladder user" v-if="item.msgType == 3">
                    <img :src="item.memberAvatar" class="conten_left" />
                    <div class="content_right">
                      <p class="name">{{ item.memberName }}</p>
                      <div class="content_text goods_type">
                        <div class="goods_model" :key="index">
                          <img
                            :src="JSON.parse(item.msgContent).goodsImage"
                            alt=""
                          />
                          <div class="goods_info">
                            <div class="goods_info_title">
                              {{ JSON.parse(item.msgContent).goodsName }}
                            </div>
                            <div class="goods_info_bottom">
                              <span
                                >￥{{
                                  JSON.parse(item.msgContent).goodsPrice
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 商品类型end -->

                  <!-- 订单类型start -->
                  <div class="chat_ladder user" v-if="item.msgType == 4">
                    <img :src="item.memberAvatar" class="conten_left" />
                    <div class="content_right">
                      <p class="name">{{ item.memberName }}</p>
                      <div class="content_text order_type">
                        <div class="order_title">
                          <span class="order_sn"
                            >订单号：<i>{{
                              JSON.parse(item.msgContent).orderSn
                            }}</i></span
                          >
                          <span class="order_time">{{
                            JSON.parse(item.msgContent).createTime
                          }}</span>
                        </div>
                        <div class="goods_model order_type">
                          <img
                            :src="JSON.parse(item.msgContent).goodsImage"
                            alt=""
                          />
                          <div class="goods_info">
                            <div class="goods_info_title">
                              {{ JSON.parse(item.msgContent).goodsName }}
                            </div>
                            <div
                              class="goods_info_bottom goods_info_bottom_detial"
                            >
                              <span
                                >￥{{
                                  JSON.parse(item.msgContent).productShowPrice
                                    ? JSON.parse(item.msgContent)
                                        .productShowPrice
                                    : JSON.parse(item.msgContent).goodsPrice
                                }}</span
                              >
                              <span class="goods_info_bottom_ok">{{
                                JSON.parse(item.msgContent).orderStateValue
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 订单类型end -->
                </template>
                <!-- 会员发送的信息end -->

                <!-- 客服发送的消息start -->
                <template v-if="item.userType == 2">
                  <div class="chat_ladder merchant" v-if="item.msgType == 6">
                    <div
                      :class="{
                        msg_read: item.msgState == 1,
                        msg_not_read: item.msgState == 2,
                      }"
                    >
                      {{ item.msgState == 1 ? "已读" : "未读" }}
                    </div>
                    <div class="content_right">
                      <audio
                        controls
                        :src="JSON.parse(item.msgContent).url"
                      ></audio>
                    </div>
                    <img :src="item.vendorAvatar" alt="" class="user_avatar" />
                  </div>

                  <!-- 文本类型start -->
                  <div class="chat_ladder merchant" v-if="item.msgType == 1">
                    <div
                      :class="{
                        msg_read: item.msgState == 1,
                        msg_not_read: item.msgState == 2,
                      }"
                    >
                      {{ item.msgState == 1 ? "已读" : "未读" }}
                    </div>
                    <div class="content_right">
                      <p class="name">{{ item.vendorName }}</p>
                      <span
                        class="content_text text_type"
                        v-html="JSON.parse(item.msgContent).content"
                      ></span>
                    </div>
                    <img :src="item.vendorAvatar" alt="" class="user_avatar" />
                  </div>
                  <!-- 文本类型end -->

                  <!-- 商品类型start -->
                  <div class="chat_ladder merchant" v-if="item.msgType == 3">
                    <div
                      :class="{
                        msg_read: item.msgState == 1,
                        msg_not_read: item.msgState == 2,
                      }"
                    >
                      {{ item.msgState == 1 ? "已读" : "未读" }}
                    </div>
                    <div class="content_right">
                      <p class="name">{{ item.vendorName }}</p>
                      <div class="content_text goods_type">
                        <div class="goods_model" :key="index">
                          <img
                            :src="JSON.parse(item.msgContent).goodsImage"
                            alt=""
                          />
                          <div class="goods_info">
                            <div class="goods_info_title">
                              {{ JSON.parse(item.msgContent).goodsName }}
                            </div>
                            <div class="goods_info_bottom">
                              <span
                                >￥{{
                                  JSON.parse(item.msgContent).goodsPrice
                                }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img :src="item.vendorAvatar" alt="" class="user_avatar" />
                  </div>
                  <!-- 商品类型end -->

                  <!-- 订单类型start -->
                  <div class="chat_ladder merchant" v-if="item.msgType == 4">
                    <div
                      :class="{
                        msg_read: item.msgState == 1,
                        msg_not_read: item.msgState == 2,
                      }"
                    >
                      {{ item.msgState == 1 ? "已读" : "未读" }}
                    </div>
                    <div class="content_right">
                      <p class="name">{{ item.vendorName }}</p>
                      <div class="content_text order_type">
                        <div class="order_title">
                          <span class="order_sn"
                            >订单号：<i>{{
                              JSON.parse(item.msgContent).orderSn
                            }}</i></span
                          >
                          <span class="order_time">{{
                            JSON.parse(item.msgContent).createTime
                          }}</span>
                        </div>
                        <div class="goods_model order_type">
                          <img
                            :src="JSON.parse(item.msgContent).goodsImage"
                            alt=""
                          />
                          <div class="goods_info">
                            <div class="goods_info_title">
                              {{ JSON.parse(item.msgContent).goodsName }}
                            </div>
                            <div
                              class="goods_info_bottom goods_info_bottom_detial"
                            >
                              <span
                                >￥{{
                                  JSON.parse(item.msgContent).goodsPrice
                                }}</span
                              >
                              <span class="goods_info_bottom_ok">{{
                                JSON.parse(item.msgContent).orderStateValue
                              }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img :src="item.vendorAvatar" alt="" class="user_avatar" />
                  </div>
                  <!-- 订单类型end -->

                  <!-- 图片类型start -->
                  <div class="chat_ladder merchant" v-if="item.msgType == 2">
                    <div
                      :class="{
                        msg_read: item.msgState == 1,
                        msg_not_read: item.msgState == 2,
                      }"
                    >
                      {{ item.msgState == 1 ? "已读" : "未读" }}
                    </div>
                    <div class="content_right">
                      <p class="name">{{ item.vendorName }}</p>
                      <div class="content_text image_type">
                        <el-image
                          :src="JSON.parse(item.msgContent).pic"
                          :preview-src-list="[JSON.parse(item.msgContent).pic]"
                          hide-on-click-modal="true"
                        >
                          <template #placeholder>
                            <div class="image-slot">
                              <i class="el-icon-picture-outline"></i>
                            </div>
                          </template>
                        </el-image>
                      </div>
                    </div>
                    <img :src="item.vendorAvatar" alt="" class="user_avatar" />
                  </div>
                  <!-- 图片类型end -->
                </template>
                <!-- 客服发送的消息end -->
              </div>
              <!-- </div> -->
              <!-- 这里加个div标签是防止一开始出现‘can not read property 0 of null’的错误 -->
              <div></div>
            </el-scrollbar>
          </div>
          <!-- 聊天界面框end -->

          <!-- 聊天输入框start -->
          <div class="chat_input" id="chat_input">
            <div class="chat_tool">
              <!-- 表情start -->
              <el-popover placement="top-start" :width="300" trigger="click">
                <template #reference>
                  <a class="face_ex">
                    <img src="@/assets/service/face_ex.png" alt="" />
                    <!-- <i class="iconfont icon-biaoqing"></i> -->
                  </a>
                </template>
                <div class="emoji_s">
                  <div
                    v-for="(e, i) in emojis"
                    :key="i"
                    :title="e.title"
                    class="emoji_item"
                    @click="transEmoji(e.src)"
                  >
                    <img :src="require('@/assets/emoji/' + e.src)" alt="" />
                  </div>
                </div>
              </el-popover>
              <!-- 表情end -->

              <!-- 图片start -->
              <label for="image">
                <img src="@/assets/service/pic.png" alt="" />
              </label>
              <input type="file" id="image" @change="getImage" />
              <!-- 图片end -->

              <!-- 快捷语恢复start -->
              <el-popover
                placement="top-start"
                :width="780"
                trigger="click"
                v-model:visible="popFlag"
              >
                <template #reference>
                  <img
                    src="@/assets/service/quick_reply.png"
                    alt=""
                    @click="getQuickReplyActive"
                  />
                </template>
                <div class="pop_header">
                  <p>常用语快捷回复</p>
                  <p><i class="el-icon-close" @click="popFlag = false"></i></p>
                </div>
                <div
                  class="pop_list"
                  v-if="
                    quickReplyList &&
                    quickReplyList.list &&
                    quickReplyList.list.length > 0
                  "
                >
                  <el-scrollbar>
                    <div
                      class="pop_item"
                      v-for="(item, index) in quickReplyList.list"
                      :key="index"
                      @click="sendQuick(item.msgContent)"
                    >
                      {{ item.msgContent }}
                    </div>
                  </el-scrollbar>
                </div>
                <div class="empty_quick" v-if="!quickReplyList.list.length > 0">
                  <img src="@/assets/goods/empty_data.png" alt="" />
                  <p>暂未设置快捷回复~</p>
                </div>
              </el-popover>
              <!-- 快捷语end -->

              <!-- 转接start -->
              <img
                src="@/assets/service/tran_touch.png"
                alt=""
                @click="chatTransfer"
              />
              <!-- 转接end -->

<!--            
              <audio  autoplay id="audio" ></audio>

              <span style="vertical-align: top;">
                  
                <el-button v-if="Audio.state===0" size="small" type="primary"  @click="handleBtnClick" >录音</el-button>
                <el-button v-else size="small"  @click="handleBtnClick" :type="Audio.state===1?'danger':'primary'"> {{Audio.state===1?`录音中`:'暂停中'}} {{`（${Audio.time}s）`}}</el-button>
                <el-button size="small" type="primary" @click="play_mp3" :disabled="Audio.state!==2">试听</el-button>
                 <el-button size="small"  type="warning"  @click="cancel" :disabled="Audio.state!==2">清除</el-button>

                <el-button size="small"  type="success"   @click="send_voice" :disabled="Audio.state!==2">发送音频</el-button>
              </span> -->

       
            </div>
            <div class="chat_input_area">
              <!-- <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea" resize="none"
                                id="el-textarea" @keyup.enter="send()">
                            </el-input> -->
              <pre
                name="sendBox"
                contenteditable="true"
                class="send-textarea"
                @input="preInput"
                ref="sendBox"
              ></pre>
            </div>
            <div class="input_button">
              <el-button size="small"  type="primary"  @click="send()">发送<i class="iconfont"></i></el-button>
            </div>
          </div>
          <!-- 聊天输入框end -->
        </div>

        <!-- 客服转接框start -->
        <el-dialog
          title="客服列表"
          v-model="dialogVisible"
          width="20%"
          :before-close="handleClose"
          top="25vh"
        >
          <el-scrollbar>
            <div class="transfer_list">
              <div
                class="transfer_item"
                v-for="(venItem, venIndex) in vendorList.list"
                :key="venIndex"
              >
                <img :src="storeInfo.data.storeLogoUrl" alt="" />
                <div class="text_con">
                  <p class="service_name">{{ venItem.vendorName }}</p>
                  <p class="service_state">在线</p>
                </div>
                <img
                  src="@/assets/service/contact_on.png"
                  alt=""
                  @click="vendorTrans(venItem.vendorId)"
                />
              </div>
              <div class="empty_trans" v-show="!vendorList.list.length > 0">
                <img src="@/assets/goods/empty_data.png" alt="" />
                <p>暂无在线客服~</p>
              </div>
            </div>
          </el-scrollbar>
        </el-dialog>
        <!-- 客服转接框end -->
      </section>
      <section v-show="!curChatMemberId">
        <div class="empty_data">
          <img src="@/assets/goods/empty_data.png" />
          <p>请选择访客开启对话～</p>
        </div>
      </section>
    </div>

    <chatRightList @getObj="getObj" ref="right" />
  </div>
</template>
<script>
import "element-plus/lib/index.full.js";
import {
  ref,
  reactive,
  getCurrentInstance,
  onMounted,
  watch,
  watchEffect,
} from "vue";
import chatLeftList from "./chatLeftList";
import chatRightList from "./chatRightList";
import { useStore } from "vuex";
import { ElMessage, ElImage } from "element-plus";
import { emoji, emojiPath } from "@/utils/data.js";
import Recorderx, { ENCODE_TYPE } from "recorderx";
const rc = new Recorderx();
let Timer=null
export default {
  name: "chatPage",
  components: {
    chatLeftList,
    chatRightList,
    ElImage,
  },
  beforeCreate() {
    this.$socket.emit("connect_success", this.connectBaseData);
    //监听连接成功事件
    this.sockets.subscribe("get_room_info", (data) => {
      this.socketInfo.data = data;
    });
    this.sockets.subscribe("get_send_msg", (data) => {
      //如果是会员发送过来的话需要播放声音
      if (
        data.userType == 1 &&
        (localStorage.msgVoiceSetting === true ||
          localStorage.msgVoiceSetting === "true")
      ) {
        this.play();
      }
      this.msgList.list.push(data);
    });
    this.sockets.subscribe("get_member_source_url", (data) => {
      this.chatSourceUrl = data.sourceUrl;
    });
    this.sockets.subscribe("get_read_msg", (data) => {
      let allData = data.msgIds.split(",");
      this.msgList.list.map((item) => {
        if (allData.indexOf(item.msgId)) {
          item.msgState = 1;
        }
      });
    });
    this.sockets.subscribe("get_member_read_all", () => {
      this.msgList.list.map((item) => {
        item.msgState = 1;
      });
    });
  },

  setup() {
    const connectBaseData = {
      storeId: localStorage.storeId,
      userId: localStorage.vendorId,
      role: 2,
    };
    // const connectBaseData = { storeId: 4, userId: 4, role: 2 };
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const quickReplyList = reactive({
      list: [],
    });
    const Audio = reactive({ show: false,state:0,time:0}); //state:{0:"无录音",1:'录音中',2:'暂停录音'}
    const storeInfo = reactive({ data: store.state.storeInfo });
    const msgVoiceSetting = ref(store.state.msgVoiceSetting);
    const textarea = ref("");
    const chatSourceUrl = ref(""); //当前会员的页面来源
    const curChatMemberId = ref(""); //当前聊天窗口会员Id
    const curChatMemberInfo = reactive({ data: {} }); //当前聊天会员的信息
    const socketInfo = reactive({ data: {} }); //socket连接成功返回的房间信息
    const msgList = reactive({ list: [] }); //聊天列表
    const dialogVisible = ref(false);
    const popFlag = ref(false);
    const hasMore = ref(true);
    const minMsgId = ref(""); //当前消息聊天记录列表里的最小消息id
    const vendorList = reactive({
      list: [],
    });
    const scrollTop = ref(false);
    const flag = ref("");
    const clientHeight = ref(0);
    const emojis = reactive(emoji);

    //录制语音
    const handleBtnClick = () => {
      //   let that = this;
      // that.news_img = !that.news_img
      if(Audio.state===1){
          cancel_mp3()
          return 
      }
      rc.start()
        .then(() => {
          //   that.news_img = !that.news_img;
          Audio.state=1
          console.log("start recording");
          Timer = setInterval(()=>{
              Audio.time+=1
          },1000)
        })
        .catch((error) => {
          alert("获取麦克风失败");
          console.log("Recording failed.", error);
        });
    };
    //暂停语音
    const cancel_mp3 = function () {
        clearInterval(Timer)
         Audio.state=2
      rc.pause();
    };
    //取消语音
    const cancel = function () {
            clearInterval(Timer)
           Audio.state=0
           Audio.time= 0

      rc.clear();
    };
    //播放语音
    const play_mp3 = function () {
      var wav = rc.getRecord({
        encodeTo: ENCODE_TYPE.WAV,
        compressible: true,
      });
      document.getElementById("audio").src = URL.createObjectURL(wav);
    };
    //发送语音
    const send_voice = function () {
      var wav = rc.getRecord({
        encodeTo: ENCODE_TYPE.WAV,
        compressible: true,
      });
      //   var uuid = this.uuid;
      //   if (this.chatList != "") {
      //     var end_time = this.chatList[this.chatList.length - 1].addtime;
      //   }

      console.log(wav);
      var formData = new window.FormData();

      // formData.append('file',wav);
      formData.append("source", "sellerAudio");

      formData.append("file", wav, Date.parse(new Date()) + ".wav");

      proxy
        .$post("/v3/oss/common/upload", formData, {
          "Content-Type": "multipart/form-data",
        })
        .then((res) => {
   
          if (res.state == 200) {
           
            let msgData = {};
            msgData.memberId = socketInfo.data.memberId;
            msgData.vendorId = socketInfo.data.vendorId;
            msgData.msgType = "6";
            msgData.msg = {
              url: res.data.url,
              duration:Audio.time
            };
            cancel()
            proxy.$socket.emit("send_msg", {
              ...msgData,
              ...connectBaseData,
            });
            proxy.$nextTick(() => {
              setTimeout(() => {
                scrolltoNewMsg();
              }, 10);
            });
          }
        });

      //   var formData = new FormData();
      //   formData.append('file',wav);
      //   formData.append("topic_id", uuid);
      //   formData.append("last_time", end_time);
      //   formData.append("type", 4);
      //   formData.append("file", wav, Date.parse(new Date()) + ".wav");
      //   let headers = { headers: { "Content-Type": "multipart/form-data" } };

      //   axios.defaults.withCredentials = true;
      //   axios
      //     .post(this.https + "/admin/api/send_reply", formData, headers)
      //     .then((data) => {
      //       that.news_img = !that.news_img;
      //       // this.reload();
      //       rc.clear();
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      //  this.$request({
      //         url: "/api/send_reply",
      //         method: "post",
      //         data:formData,
      //        headers: {"Content-Type": "multipart/form-data"}
      //       }).then(data => {
      //           that.news_img = !that.news_img
      //           this.content = "";
      //         })
      //         .catch(err => {
      //           console.log(err);
      //         });
    };

    //发送消息
    const send = () => {
      let content = textarea.value; //没有内容的话直接结束
      if (!content.trim() && !content) {
        return false;
      }
      let msgData = {};
      msgData.memberId = socketInfo.data.memberId;
      msgData.vendorId = socketInfo.data.vendorId;
      msgData.msgType = "1";
      msgData.msg = {
        content: content,
      };
      proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
      textarea.value = ""; //清空输入框的内容
      proxy.$refs.sendBox.innerHTML = "";
      scrolltoNewMsg();
    };

    //打开客服转接
    const chatTransfer = () => {
      dialogVisible.value = true;
      proxy.$socket.emit(
        "pre_switch_vendor",
        { ...connectBaseData },
        (data) => {
          vendorList.list = data;
        }
      );
    };

    // 点击转接客服
    const vendorTrans = (vendorId) => {
      proxy.$socket.emit(
        "switch_vendor",
        {
          switchVendorId: vendorId,
          memberId: curChatMemberId.value,
          ...connectBaseData,
        },
        (res) => {
          if (res.state == 200) {
            //删除将该会员从最近联系人列表中删除
            proxy.$refs.left.closeChatMember(curChatMemberId.value);
            ElMessage({
              showClose: true,
              message: res.msg,
              type: "success",
            });
            dialogVisible.value = false;
          }
        }
      );
    };

    //获取现在的会员的信息
    const curChatMemInfo = (data) => {
      if (data.memberId != undefined && data.memberId) {
        curChatMemberInfo.data = data;
      } else {
        curChatMemberId.value = 0;
      }
    };

    //切换会员需要重新获取聊天列表以及用户的信息
    const switchMember = (data) => {
      let { memberId, memberName } = data;
      curChatMemberInfo.data.memberName = memberName;
      curChatMemberId.value = memberId;
      scrollTop.value = false;
      hasMore.value = true;
      flag.value = memberId.toString();
      minMsgId.value = "";
      msgList.list = [];

      getChatLog();
      proxy.$refs.right.getSwitch(curChatMemberId.value);
    };

    //获取订单，足迹，推荐商品，并发送
    const getObj = (data, type) => {
      let msgData = {};
      msgData.memberId = socketInfo.data.memberId;
      msgData.vendorId = socketInfo.data.vendorId;
      if (type == "foot") {
        msgData.msgType = 3;
        msgData.msg = {
          productId: data.productId,
          goodsImage: data.goodsImage,
          goodsName: data.goodsName,
          goodsPrice: data.productPrice,
        };
      } else if (type == "recom") {
        msgData.msgType = 3;
        msgData.msg = {
          productId: data.productId,
          goodsImage: data.mainImage,
          goodsName: data.goodsName,
          goodsPrice: data.goodsPrice,
        };
      } else if (type === "order") {
        msgData.msgType = 4;
        msgData.msg = {
          orderSn: data.orderSn,
          createTime: data.createTime,
          orderStateValue: data.orderStateValue,
          productId: data.goodsItem.productId,
          goodsImage: data.goodsItem.productImage,
          goodsName: data.goodsItem.goodsName,
          goodsPrice: data.goodsItem.productShowPrice,
        };
      }
      proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
      scrolltoNewMsg();
    };

    //获取快捷回复列表
    const getQuickReplyActive = () => {
      popFlag.value = true;
      let params = {
        current: 1,
      };
      proxy.$get("v3/helpdesk/seller/quick/list", params).then((res) => {
        if (res.state == 200) {
          quickReplyList.list = res.data.list.filter(
            (item) => item.isShow == 1
          );
        }
      });
    };

    //获取聊天记录
    const getChatLog = () => {
      let params = {
        memberId: curChatMemberId.value,
      };
      if (minMsgId.value) {
        params.msgId = minMsgId.value;
      }
      proxy.$post("v3/helpdesk/seller/chat/msgLog", params).then((res) => {
        if (res.state == 200) {
          if (minMsgId.value) {
            msgList.list = Array.from(res.data).concat(msgList.list);
          } else {
            msgList.list = res.data;
          }
          if (res.data.length > 0) {
            changeMsgState(res.data);
          }
          if (msgList.list.length > 0) {
            minMsgId.value = msgList.list[0].msgId;
          }
          hasMore.value = res.data.length < 10 ? false : true;
        }
      });
    };

    const transEmoji = (src) => {
      const imgSrc = emojiPath + "" + src;
      const imgTag = document.createElement("img");
      imgTag.src = imgSrc;
      proxy.$refs.sendBox.appendChild(imgTag);
      textarea.value = proxy.$refs.sendBox.innerHTML;
    };

    const preInput = (e) => {
      textarea.value = e.target.innerHTML;
    };

    //修改当前消息列表的未读消息为已读
    const changeMsgState = (data) => {
      let tmpMsgIdArray = [];
      data.map((item) => {
        if (item.userType == 1 && item.msgState == 2) {
          tmpMsgIdArray.push(item.msgId);
        }
      });
      if (tmpMsgIdArray.length > 0) {
        proxy.$socket.emit("read_msg", {
          msgIds: tmpMsgIdArray.join(","),
          memberId: curChatMemberId.value,
          ...connectBaseData,
        });
      }
    };

    //切换店铺的滑动操作，而且触顶加载则保持滚动条在触顶时的位置，不大跳
    watch(
      () => flag.value,
      (nv, ov) => {
        let el = "";
        let client = "";
        if (nv != ov) {
          el = document.getElementById("chat").childNodes[0];
          client = document.getElementById("chat");
          scrollTop.value = false;
          new MutationObserver(() => {
            if (scrollTop.value) {
              el.scrollTop = client.clientHeight + 400;
              return;
            }
            scrollToBottom(el);
          }).observe(el, { childList: true, subtree: true });
        }
      }
    );

    watchEffect(() => {});

    //滑动至底部方法
    const scrollToBottom = (el) => {
      el.scrollTop = el.scrollHeight;
      el.scrollTo(0, el.scrollHeight);
    };

    //MutationObserver监听dom变化，当聊天数据最终渲染完成时，将其滑动至底部(优化方法)
    const scroll = () => {
      let el = proxy.$refs.scroll.wrap;
      let timeout = "";
      el.addEventListener(
        "scroll",
        () => {
          // console.log(el.scrollTop, 'scrollTop111111')
          if (timeout) clearTimeout(timeout);
          timeout = window.setTimeout(function () {
            if (el.scrollTop == 0 && hasMore.value) {
              // console.log(el.scrollTop, 'scrollTop')
              // console.log('scrollTop')
              scrollTop.value = true;
              getChatLog();
            }
          }, 500);
        },
        true
      );
      new MutationObserver(() => {
        if (scrollTop.value) {
          el.scrollTop = el.clientHeight + 400;
          return;
        }
        scrollToBottom(el);
      }).observe(el, { childList: true, subtree: true });
    };

    //发送快捷回复
    const sendQuick = (msg) => {
      let msgData = {};
      msgData.memberId = socketInfo.data.memberId;
      msgData.vendorId = socketInfo.data.vendorId;
      msgData.msgType = "1";
      msgData.msg = {
        content: msg,
      };

      popFlag.value = false;
      proxy.$socket.emit("send_msg", { ...msgData, ...connectBaseData });
      scrolltoNewMsg();
    };

    //发送消息时并，滚到最底部
    const scrolltoNewMsg = () => {
      var div = proxy.$refs.scroll.wrap;
      proxy.$nextTick(() => {
        setTimeout(() => {
          div.scrollTo(0, div.scrollHeight + 10000);
        }, 20);
      });
    };

    // 消息提示音
    const play = () => {
      let audioElement = document.createElement("audio");
      audioElement.setAttribute(
        "src",
        "https://www.slodon.cn/vendor/views/default/media/msg.mp3"
      );
      audioElement.setAttribute("autoplay", "autoplay");
    };

    // 发送图片
    const getImage = (e) => {
      if (e.target.files[0]) {
        proxy
          .$post(
            "/v3/oss/common/upload",
            {
              source: "goods",
              file: e.target.files[0],
            },
            "form"
          )
          .then((res) => {
            if (res.state == 200) {
              let msgData = {};
              msgData.memberId = socketInfo.data.memberId;
              msgData.vendorId = socketInfo.data.vendorId;
              msgData.msgType = "2";
              msgData.msg = {
                pic: res.data.url,
                width: res.data.width,
                height: res.data.height,
              };
              proxy.$socket.emit("send_msg", {
                ...msgData,
                ...connectBaseData,
              });
              proxy.$nextTick(() => {
                setTimeout(() => {
                  scrolltoNewMsg();
                }, 10);
              });
            }
          });
      }
    };

    onMounted(() => {
      clientHeight.value =
        document.body.clientHeight || document.documentElement.clientHeight;
      scroll();
    });

    return {
      Audio,
      handleBtnClick,
      cancel_mp3,
      cancel,
      play_mp3,
      send_voice,
      textarea,
      msgList,
      socketInfo,
      send,
      chatTransfer,
      dialogVisible,
      getObj,
      getQuickReplyActive,
      quickReplyList,
      sendQuick,
      popFlag,
      getChatLog,
      chatSourceUrl,
      curChatMemInfo,
      curChatMemberInfo,
      switchMember,
      minMsgId,
      changeMsgState,
      curChatMemberId,
      vendorList,
      vendorTrans,
      play,
      msgVoiceSetting,
      getImage,
      connectBaseData,
      storeInfo,
      clientHeight,
      emojis,
      preInput,
      transEmoji,
      emojiPath,
    };
  },
};
</script>
<style lang="scss">
.pop_header {
  height: 38px;
  background: #f3f3f4;
  display: flex;
  justify-content: space-between;

  p {
    margin-left: 10px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 38px;
  }

  i {
    font-size: 16px;
    margin-right: 10px;
  }
}

.emoji_s {
  height: 200px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;

  .emoji_item {
    padding: 0 5px;
  }
}

.send-textarea {
  box-sizing: border-box;
  width: 100%;
  height: 94px;
  padding: 0px 19px 0px 19px;
  background-color: #fff;
  color: #252525;
  font-size: 13px;
  font-family: inherit;
  word-break: break-all;
  white-space: normal;
  overflow-y: auto;
  outline: none;
}

.pop_list {
  margin-top: 5px;
  height: 300px;

  .pop_item {
    margin-left: 10px;
    padding: 12px 0px;
    border-bottom: 1px solid #e7e7e7;
    width: 97%;

    &:hover {
      background: #f8f8f8;
    }
  }
}

.goods_info_bottom_detial {
  position: relative;

  .goods_info_bottom_ok {
    position: absolute;
    padding-left: 5px;
    padding-right: 5px;
    height: 20px;
    background: #eeeeee;
    border-radius: 10px;
    bottom: 0;
    right: 10px;
    text-align: center;
    line-height: 20px;
    color: #666666 !important;
  }
}

#el-textarea {
  border: none;

  textarea {
    outline: none;
    border: none;
  }
}

.sld_chat_page {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background-color: #fff;
  display: flex;
  flex-shrink: 0;

  .chat_zone {
    width: 1010px;
    height: 100%;

    .empty_data {
      padding-top: 220px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        width: 80px;
      }

      p {
        margin-top: 10px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #bbb;
      }
    }

    .top {
      height: 50px;
      padding: 15px 0px;

      .top_title {
        height: 20px;
        line-height: 20px;
        border-left: 4px solid #0563ff;
        padding-left: 10px;
        margin-left: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
    }

    .chat_wrap {
      width: 100%;
      border-radius: 6px;
      /* border-top-right-radius: 6px; */
      border: 1px solid #eeeeee;

      .chat_window {
        height: 580px;
        background-color: #f5f5f5;
        padding-top: 12px;
        padding-left: 15px;
        padding-right: 13px;

        .time_line {
          margin: 12px auto;
          line-height: 22px;
          text-align: center;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #999999;
          width: 149px;
          height: 22px;
          background: #eeeeee;
          border-radius: 11px;
        }

        #text_center {
          width: 589px;
          height: 104px;
          background: #ffffff;
          border-radius: 6px;

          .goods_model {
            display: flex;
            justify-content: center;
            align-content: center;
            padding-top: 10px;
            padding-left: 10px;
          }
        }

        .chat_ladder {
          display: flex;
          align-items: flex-end;
          margin-top: 20px;
          margin-bottom: 20px;
          align-items: flex-start;
          box-sizing: border-box;

          & > img {
            width: 38px;
            height: 38px;
            border-radius: 50%;

            &.user_avatar {
              margin-left: 10px;
            }
          }

          .content_right {
            margin-left: 10px;

            p {
              font-size: 12px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #999999;
              margin-bottom: 5px;
            }

            .content_text {
              display: inline-block;
              padding: 11px;
              position: relative;
              max-width: 589px;
              background: #ffffff;
              border-radius: 6px;
            }

            .order_type {
              .goods_info {
                min-width: 300px;

                .goods_info_title {
                  font-size: 16px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #333333;
                }
              }

              .order_title {
                display: flex;
                height: 20px;
                justify-content: space-between;
                align-items: flex-start;
                border-bottom: 1px solid #f2f2f2;
                margin-bottom: 8px;

                .order_sn {
                  font-size: 12px;
                  color: #999999;
                  font-family: Microsoft YaHei;

                  i {
                    font-style: normal;
                    color: #666666;
                  }
                }

                .order_time {
                  color: #999999;
                }
              }
            }

            .goods_type {
              min-width: 300px;
            }

            .image_type {
              min-height: 100px;

              img {
                max-width: 200px;
              }

              .image-slot {
                width: 200px;
                height: 200px;
              }
            }

            .text_type {
              max-width: 360px;
              word-break: break-all;
            }
          }
        }

        .user {
          justify-content: flex-start;

          .content_text {
            background: #ffffff;

            border-radius: 6px;
          }
        }

        .merchant {
          padding-right: 20px;
          justify-content: flex-end;

          p {
            text-align: right;
          }

          .content_text {
            .tiny_triangle {
              position: absolute;
              right: -9px;
              width: 0;
              height: 0;
              border-right: 14px solid transparent;
              border-bottom: 13px solid #fff;
            }

            background: #fff;
            border-radius: 6px;
          }

          .msg_read {
            align-self: flex-end;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }

          .msg_not_read {
            align-self: flex-end;
            height: 100%;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #999999;
          }
        }
      }

      .chat_input {
        padding-bottom: 10px;

        .chat_tool {
          padding: 6px;

          img {
            margin-right: 10px;
          }
        }

        .chat_input_area {
          padding: 6px;
          margin-top: 6px;

          textarea {
            max-height: 94px;
          }
        }

        button {
        //   width: 80px;
        //   height: 30px;
        //   background: #0e6fd7;
        //   border-radius: 6px;
        //   border: none;
        //   color: #fff;
        }

        .input_button {
          display: flex;
          justify-content: flex-end;
          padding-right: 23px;

          button {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

.goods_model {
  display: flex;

  & > img {
    width: 84px;
    height: 84px;
    background: #707070;
    border-radius: 6px;
  }

  .goods_info {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 450px;

    .goods_info_title {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      padding-right: 10px;
    }

    .goods_info_bottom {
      display: flex;
      justify-content: flex-start;

      span:first-child {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #e2231a;
      }

      span:nth-child(2) {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #e2231a;
      }
    }
  }
}

.transfer_list {
  padding: 11px 0px 11px 11px;
  height: 317px;

  .transfer_item {
    display: flex;
    border-bottom: 1px solid #f2f2f2;
    padding: 12px 0px;
    position: relative;
    height: 70px;
    width: 98%;

    img {
      width: 40px;
      height: 40px;
    }

    img:nth-of-type(2) {
      position: absolute;
      right: 10px;
      top: 22px;
      width: 23px;
      height: 22px;
    }

    .text_con {
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .service_name {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }

      .service_state {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0e6fd7;
        margin-bottom: 3px;
      }
    }
  }
}

.el-dialog__header {
  background: #f3f3f4;
}

.el-dialog__title {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}

.empty_quick {
  height: 330px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 87px;
    height: 55px;
  }

  p {
    margin-top: 11px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
}

.empty_trans {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;

  p {
    margin-top: 11px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
  }
}

#image {
  display: none;
}
</style>